<template>
  <div>
    <v-card
      width="700px"
      class="mx-auto rounded-xl"
    >
      <!-- Card Tabs -->
      <v-tabs
        v-model="userTab"
        show-arrows
        center-active
        mobile-breakpoint="1"
        fixed-tabs
        centered
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
        >
          {{ tab.title }}
          <v-icon class="px-2">
            {{ tab.icon }}
          </v-icon>
        </v-tab>
      </v-tabs>

      <v-card>
        <v-card-text>
          <v-tabs-items v-model="userTab">
            <!-- First Tab -->
            <v-tab-item>
              <v-form
                ref="guestDetails"
                class="multi-col-validation pt-4"
              >
                <v-row
                  justify="center"
                  class="ml-auto"
                >
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <!-- Full Name -->
                    <v-text-field
                      v-model="userData.fullName"
                      :rules="[validators.required, validators.maxLenInput, validators.minLenInput]"
                      outlined
                      dense
                      :label="$t('fullName')"
                      hide-details
                      rounded
                      class="mb-6"
                    ></v-text-field>

                    <!-- phone number -->
                    <v-text-field
                      v-model="phoneNum"
                      :rules="[validators.required, validators.integerValidator, validators.maxPhoneNumber, validators.PhoneNumberLength ]"
                      outlined
                      dense
                      rounded
                      :label="$t('phoneNumber')"
                      type="number"
                      hide-spin-buttons
                      hide-details
                      class="mb-6"
                    ></v-text-field>

                    <!-- count of invitees to event -->
                    <v-text-field
                      v-model="userData.countOfInvites"
                      outlined
                      dense
                      :label="$t('countOfInvites')"
                      hide-details
                      rounded
                      class="mb-6"
                      type="number"
                      :min="1"
                    ></v-text-field>

                    <!-- group -->
                    <v-select
                      v-if="$store.state.groupsData.length > 0"
                      v-model="userData.groups"
                      :items="$store.state.groupsData"
                      item-text="name"
                      item-value="id"
                      :label="$t('ClassGroup')"
                      outlined
                      small-chips
                      rounded
                      deletable-chips
                      hide-details
                      class="mb-6"
                      dense
                    >
                    </v-select>

                    <!-- language -->
                    <v-select
                      v-model="userData.lang"
                      :items="$store.state.eventData.languages"
                      :item-text="item => $t(item.lang)"
                      item-value="lang"
                      outlined
                      dense
                      rounded
                      :label="$t('Language')"
                      hide-details
                      class="mb-6"
                    >
                    </v-select>
                    <!-- if the event not have groups, show btn to create new group -->
                    <v-btn
                      v-if="$store.state.groupsData.length === 0"
                      :to="{name: 'apps-user-manage-group'}"
                      color="primary"
                      outlined
                      rounded
                      class="mb-6"
                    >
                      {{ $t('NewGroup') }}
                    </v-btn>

                    <!-- Switch for transport -->
                    <v-row
                      v-if="($store.state.eventData.transport !== 0)"
                      class="mt-auto ma-1"
                      no-gutters
                    >
                      <v-col
                        cols="10"
                        style="display: flex; align-items: flex-start;"
                      >
                        <label class="font-weight-bold">{{ $t('AskTransportation') }}</label>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-switch
                          v-model="userData.canAskTransport"
                          hide-details
                          class="pt-0 mt-0 pb-3"
                        >
                        </v-switch>
                      </v-col>
                    </v-row>

                    <!-- Switch for special meals -->
                    <v-row
                      v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
                      class="mt-auto ma-1"
                      no-gutters
                    >
                      <v-col
                        cols="10"
                        style="display: flex; align-items: flex-start;"
                      >
                        <label class="font-weight-bold">{{ $t('AskSpecialMeals') }}</label>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-switch
                          v-model="userData.canAskSpecialDish"
                          hide-details
                          class="pt-0 mt-0 pb-3"
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- open warning popup if the number of invitees is bigger than the number of guest responsed -->
                  <v-dialog
                    v-if="limitCountOfInvites"
                    v-model="limitCountOfInvites"
                    persistent
                    width="500"
                  >
                    <v-card
                      height="100%"
                      flat
                      class="rounded-xl"
                    >
                      <v-card-title
                        class="align-center justify-center pb-2"
                      >
                        <v-icon
                          right
                          color="primary"
                          size="40"
                        >
                          {{ icons.mdiAlertCircleOutline }}
                        </v-icon>
                      </v-card-title>
                      <v-card-text
                        class="text-xl text-center pt-0 mt-0"
                        style="word-break: break-word"
                      >
                        {{ $t('limitCountOfInvitesMessage') }}
                      </v-card-text>
                      <v-card-actions
                        class="mt-6"
                      >
                        <v-spacer></v-spacer>
                        <v-flex>
                          <v-btn
                            color="primary"
                            outlined
                            rounded
                            @click="limitCountOfInvites = false"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>
                        </v-flex>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      color="primary"
                      class="me-3"
                      type="submit"
                      rounded
                      @click="checkValidCountOfInvites"
                      @click.prevent="onSubmit"
                    >
                      {{ $t('Saving') }}
                    </v-btn>
                    <v-btn
                      outlined
                      color="secondary"
                      rounded
                      @click="guestIdRouter()"
                    >
                      {{ $t('Cancel') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>

            <!-- Second tab -->
            <v-tab-item>
              <v-form
                ref="guestAnswersForm"
                class="multi-col-validation pt-4"
              >
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <!-- Status -->
                    <v-select
                      v-model="userData.status"
                      :items="statusOptionsStats"
                      :label="$t('status')"
                      :item-text="item => $t(item.title)"
                      item-value="value"
                      outlined
                      rounded
                      dense
                      hide-details
                      class="mb-6"
                      @change="updateCountOfGuests"
                    >
                    </v-select>
                    <!-- count of guests that responsed -->
                    <v-text-field
                      v-model="userData.countOfGuests"
                      :disabled="![guestStatusMapping.COMING].includes(userData.status)"
                      :label="$t('NumberGuestAnswer')"
                      :rules="[validators.attendingNotZero(userData.status, userData.countOfGuests)]"
                      outlined
                      dense
                      rounded
                      hide-details="auto"
                      class="mb-6"
                      type="number"
                      :min="1"
                    >
                    </v-text-field>

                    <!-- Transport options -->
                    <v-select
                      v-if="($store.state.eventData.transport !== 0)"
                      v-model="userData.guestTransports"
                      :label="$t('Transport')"
                      :items="$store.state.eventData.transports"
                      item-text="name"
                      item-value="id"
                      offset-y
                      hide-details
                      clearable
                      outlined
                      rounded
                      dense
                      class="mb-6"
                    >
                    </v-select>

                    <!-- Special meal option -->
                    <v-select
                      v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
                      v-model="userData.guestSpecialMeals"
                      :label="$t('specialMeals')"
                      :items="$store.state.eventData.specialMeals"
                      :item-text="item => $t(item.name)"
                      item-value="value"
                      offset-y
                      outlined
                      rounded
                      clearable
                      hide-details
                      dense
                      class="mb-6"
                    >
                    </v-select>

                    <!-- comment -->
                    <v-textarea
                      v-model="userData.comment"
                      hide-details
                      auto-grow
                      :label="$t('comments')"
                      outlined
                      dense
                      rounded
                      class="mb-6"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-center "
                  >
                    <v-btn
                      color="primary"
                      class="me-3"
                      rounded
                      type="submit"
                      @click.prevent="onSubmit"
                    >
                      {{ $t('Saving') }}
                    </v-btn>

                    <v-btn
                      outlined
                      color="secondary"
                      rounded
                      @click="guestIdRouter()"
                    >
                      {{ $t('Cancel') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import useGuestStatuses, { guestStatusMapping } from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import inviteListStore from '@/store/modules/inviteListStore'
import { useRouter } from '@core/utils'
import {
  PhoneNumberLength,
  attendingNotZero,
  integerValidator,
  maxLenInput,
  maxPhoneNumber,
  minLenInput,
  required,
} from '@core/utils/validation'
import { mdiAccountOutline, mdiAlertCircleOutline, mdiLinkVariant } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

export default {
  props: {
    guestData: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const { mealsTypes } = useSpecialMealsTypes()
    const userData = ref({})
    const userTab = ref(null)
    const guestAnswersForm = ref()
    const guestDetails = ref()
    const toast = useToast()
    const showToast = message => toast.error(i18n.t(message))
    const phoneNum = ref()
    const limitCountOfInvites = ref(false)
    const { statusOptions } = useGuestStatuses()
    const { route } = useRouter()

    const INVITE_STORE_MODULE_NAME = 'inviteList'

    // Register module
    if (!store.hasModule(INVITE_STORE_MODULE_NAME)) store.registerModule(INVITE_STORE_MODULE_NAME, inviteListStore)

    // Check if the number of invitee is bigger than the number of guest responsed
    const checkValidCountOfInvites = () => {
      if (Number(userData.value.countOfInvites) < Number(userData.value.countOfGuests)) {
        limitCountOfInvites.value = true
      } else {
        limitCountOfInvites.value = false
      }
    }

    const filterKey = store.state.eventData.progressStatus === 1 ? 'pending' : 'inProgress'
    const excludedTitles = ['viewCount', 'wrongNumber', 'partiallyComing', filterKey]

    const statusOptionsStats = statusOptions

      // Remove view invitation status && the filtered status
      .filter(el => !excludedTitles.includes(el.title))
      .map(el => {
        // If the progressStatus is 1(in process) disable to return to this status after changed it,
        if (filterKey === 'pending' && el.title === 'inProgress') {
          return { ...el, disabled: true }
        }

        return el
      })

    const guestIdRouter = () => {
      const guestId = userData.value.id ?? route.value.params.guestID
      router.push({ name: 'apps-user-list', params: { guestId } })
    }

    const fetchGuests = async () => {
      if (props.guestData) {
        userData.value = props.guestData
      } else {
        try {
          // if the page refreshed
          const res = await store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: false } })
          userData.value = res.data.find(el => el.id === Number(route.value.params.guestID))
        } catch (error) {
          guestIdRouter()
        }
      }
      if (userData.value) {
        userData.value.groups = userData.value.groups[0] ? userData.value.groups[0].id : null
        userData.value.guestTransports = userData.value.guestTransports[0] ? userData.value.guestTransports[0].id : null
        userData.value.guestSpecialMeals = userData.value.guestSpecialMeals[0]
          ? userData.value.guestSpecialMeals[0].value
          : null
        phoneNum.value = `0${userData.value.phoneNumber.slice(4)}`
      }
    }
    fetchGuests()

    // if guest not attending to the event, set count of guests to 0.
    const updateCountOfGuests = () => {
      if (userData.value.status === guestStatusMapping.NOT_COMING) {
        userData.value.countOfGuests = 0
      } else {
        userData.value.countOfGuests = userData.value.countOfInvites
      }
    }

    const onSubmit = () => {
      if (userData.value) {
        const {
          fullName,
          countOfInvites,
          groups,
          lang,
          canAskTransport,
          canAskSpecialDish,
          status,
          countOfGuests,
          guestTransports,
          guestSpecialMeals,
          comment,
        } = userData.value

        const data = {
          fullName,
          phoneNumber: `+972${phoneNum.value.slice(1, 10)}`,
          countOfInvites: Number(countOfInvites),
          groups: [groups],
          lang,
          canAskTransport,
          canAskSpecialDish,
          status,
          countOfGuests: Number(countOfGuests),
          guestTransports: [guestTransports],
          guestSpecialMeals: [guestSpecialMeals],
          comment,
        }
        if (
          guestAnswersForm?.value?.validate() ||
          (guestAnswersForm.value === undefined && guestDetails.value.validate())
        ) {
          if (!limitCountOfInvites.value) {
            store
              .dispatch('editGuest', { id: store.state.eventData.id, guestID: userData.value.id, guestData: data })
              .then(() => {
                guestIdRouter()
              })
              .catch(error => {
                if (error.response.data.message === 'invalid_guests_coming') {
                  showToast(i18n.t('invalid_guests_coming'))
                } else {
                  showToast(i18n.t('errorManageEvents'))
                }
              })
          }
        }
      }
    }

    const tabs = [
      { icon: mdiAccountOutline, title: i18n.t('GuestDetails') },
      { icon: mdiLinkVariant, title: i18n.t('GuestAnswer') },
    ]

    return {
      tabs,
      userTab,
      userData,
      statusOptions,
      guestIdRouter,
      onSubmit,
      phoneNum,
      limitCountOfInvites,
      checkValidCountOfInvites,
      statusOptionsStats,
      guestAnswersForm,
      guestDetails,
      guestStatusMapping,
      updateCountOfGuests,
      mealsTypes,

      validators: {
        required,
        integerValidator,
        maxLenInput,
        minLenInput,
        maxPhoneNumber,
        attendingNotZero,
        PhoneNumberLength,
      },
      icons: {
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>

  <style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
