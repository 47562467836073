import axios from '@axios'

export default {
  namespaced: true,
  state: {
    statusFilter: null,
    groupsFilter: null,
    specialMealsFilter: null,
    transportFilter: null,
    viewedInvitationFilter: null,
    userListTable: [],
    wrongNumberListTable: [],
    lastGuestsFetched: [],
    numberOfInvitees: null,
    loading: true,
    showLimitReachedPopup: false,
  },
  getters: {
    getShowLimitReachedPopup(state) {
      return state.showLimitReachedPopup
    },
    getUserListTable(state) {
      return state.userListTable
    },
    getWrongNumberListTable(state) {
      return state.wrongNumberListTable
    },
    getLoading(state) {
      return state.loading
    },
    getNumberOfInvitees(state) {
      return state.numberOfInvitees
    },
    getLastFetchedGuests(state) {
      return state.lastGuestsFetched
    },
    getStatusFilter(state) {
      return state.statusFilter
    },
    getGroupFilter(state) {
      return state.groupsFilter
    },
    getSpecialMealsFilter(state) {
      return state.specialMealsFilter
    },
    getTransportFilter(state) {
      return state.transportFilter
    },
    getViewedInvitationFilter(state) {
      return state.viewedInvitationFilter
    },
  },
  mutations: {
    setShowLimitReachedPopup(state, status) {
      state.showLimitReachedPopup = status
    },
    setStatusFilter(state, status) {
      state.statusFilter = status
    },
    setGroupFilter(state, groups) {
      state.groupsFilter = groups
    },
    setSpecialMealsFilter(state, specialMealsFilter) {
      state.specialMealsFilter = specialMealsFilter
    },
    setTransportFilter(state, transportFilter) {
      state.transportFilter = transportFilter
    },
    setViewedInvitationFilter(state, viewedInvitationFilter) {
      state.viewedInvitationFilter = viewedInvitationFilter
    },
    setLoading(state, loading = true) {
      state.loading = loading
    },
    setNumberOfInvitees(state, data) {
      state.numberOfInvitees = data
    },
    setWrongNumberList(state, data) {
      state.wrongNumberListTable = data
    },
    updateGuests(state, data) {
      state.userListTable = data
      state.lastGuestsFetched = data
    },
    searchGuests(state, query) {
      state.userListTable = state.lastGuestsFetched.filter(
        user =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          user.fullName.search(query) !== -1 ||
          user.phoneNumber.search(query[0] === '0' ? query.slice(1) : query) !== -1 ||
          user.comment.search(query) !== -1,
      )
    },

    setFirstGuestInTable(state, guestId) {
      const index = state.userListTable.findIndex(e => e.id === guestId)
      state.userListTable.unshift(state.userListTable.splice(index, 1)[0])
    },
  },
  actions: {
    fetchGuests(ctx, { payload = null } = {}) {
      const setLoad = val => {
        if (payload?.shouldLoad) {
          ctx.commit('setLoading', val)
        }
      }
      const { id } = ctx.rootState.eventData
      const filters = {
        status: ctx.state.statusFilter,
        groups: ctx.state.groupsFilter ? ctx.state.groupsFilter.join() : null,
        specialMeals: ctx.state.specialMealsFilter ? ctx.state.specialMealsFilter.join() : null,
        transport: ctx.state.transportFilter ? ctx.state.transportFilter.join() : null,
        viewedInvitation: ctx.state.viewedInvitationFilter,
      }

      return new Promise((resolve, reject) => {
        setLoad(true)
        ctx.dispatch('eventStatisticsDetails', { id }, { root: true })
        axios
          .get(`guests/fetch/${id}`, { params: filters })
          .then(response => {
            if (Object.values(filters).every(value => value === null)) {
              ctx.commit('setNumberOfInvitees', response.data.length)
            }
            ctx.commit('updateGuests', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            ctx.commit('setLoading', false)
          })
      })
    },

    fetchGuestsWithWrongNumber(ctx) {
      const { id } = ctx.rootState.eventData

      return new Promise((resolve, reject) => {
        axios
          .get(`guests/fetch/${id}`, { params: { status: 4 } })
          .then(response => {
            ctx.commit('setWrongNumberList', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteGuests(ctx, { guestIds }) {
      const { id } = ctx.rootState.eventData
      const joinedGuestIds = guestIds.join()

      return new Promise((resolve, reject) => {
        axios
          .delete(`guests/${id}/${joinedGuestIds}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editManyGuests(ctx, { guestsData }) {
      const { id } = ctx.rootState.eventData

      return new Promise((resolve, reject) => {
        axios
          .put(`guests/many/${id}`, { ...guestsData })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    submitContacts(ctx, data) {
      const { id } = ctx.rootState.eventData

      return new Promise((resolve, reject) => {
        axios
          .post(`contacts/import/${id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    exportDataToRegularExcel(ctx, { lang }) {
      const { id } = ctx.rootState.eventData

      return new Promise((resolve, reject) => {
        axios
          .get(`export/${id}/${lang}`, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    exportDataToIplanExcel(ctx) {
      const { id } = ctx.rootState.eventData

      return new Promise((resolve, reject) => {
        axios
          .get(`export/iplan/${id}`, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    internalSearchGuests(ctx, { queryLowered }) {
      ctx.commit('searchGuests', queryLowered)
    },
    placeFirstGuestInTable(ctx, { guestId }) {
      ctx.commit('setFirstGuestInTable', guestId)
    },
    toggleGuestStatusFilter(ctx, { type }) {
      if (ctx.state.statusFilter !== type) {
        ctx.commit('setStatusFilter', type)
      } else {
        ctx.commit('setStatusFilter', null)
      }
      ctx.dispatch('fetchGuests', { payload: { shouldLoad: false } })
    },
  },
}
