export default function useSpecialMealsTypes() {
  const mealsTypes = {
    NO_NEED_TO_ASK: 0,
    SOME_GUESTS: 1,
    ALL_GUESTS: 2,
  }
  const specialMealsTypes = [
    { name: 'no_need_to_ask', value: mealsTypes.NO_NEED_TO_ASK },
    { name: 'some_guests', value: mealsTypes.SOME_GUESTS },
    { name: 'all_guests', value: mealsTypes.ALL_GUESTS },
  ]

  return {
    mealsTypes,
    specialMealsTypes,
  }
}
